<!-- 双色球复式中奖计算 -->
<template>
    <div class="">
      <ssqfu :fu3="ssq"></ssqfu>
      <div class="clear"></div>
    </div>
  </template>
  
  <script>
  //import '../js/mpublic.js' //js文件引入，注意路径
  import ssqfu from "@/components/jin/fu.vue";
  //import api from '@/js/method'
  export default {
    name: "",
    components: {
      ssqfu,
    },
    data() {
      // 数据源
      return {
         ssq: [{"wanfa":'klb',"xh":"复式个数","xl":"蓝球","zh":"命中个数","zl":"中蓝",'xd':'胆码','xt':'拖码','zd':'中胆','zt':'中拖','pic':require('@/assets/jpg/klb.jpg')}],
      };
    },
    mounted() {},
    computed: {},
    methods: {
      // 函数体部分，js的主要逻辑控制
    
    },
    props: {},
  };
  </script>
  
  
  <style scoped>
  /* @import './swiper.css'; */
  
  .clear {
    clear: both;
  }
  </style>